import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import RemoteIcon from '@mui/icons-material/SettingsRemote'
import AppsIcon from '@mui/icons-material/AppsSharp';
import TvIcon from '@mui/icons-material/Tv';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useNavigate, useLocation } from "react-router";

const StyledButton = styled(Button)(({theme})=>({
   'display': 'flex',
}))

const StyledBox = styled(Box)(({theme})=>({ 
   color:'white',
   'display': 'flex',
   'flexGrow': 1,
   'alignItems': 'center',
   'margin': '0px 10px 0px 10px',
   'flexDirection': 'column',
   fontSize:'9px',
   '@media (max-width: 386px)':{
      fontSize:'7px',
   },
   'opacity': 0.8
}))

const selectedStyle = {
   boxShadow: '0px 0px 10px 6px rgba(55,114,176,1)',
   background: 'rgba(55,114,176,1)',
   color: 'rgba(225, 210, 149, 1)'
}



const BottomNavBar = (props)=>{
   const navigate = useNavigate();
   const location = useLocation();
   return <Box sx={{
         'display':'flex',
         'flexGrow':1,
         'justifyContent': 'space-evenly',
         'background': 'rgba(52,74,92,1)',
         'maxHeight': '70px',
         'borderRadius': '10px',
         // 'margin': '15px 28px 0px 28px'
         'marginLeft':'48px',
         'marginRight':'48px',
         '@media (max-width: 358px)':{
            'marginLeft':'24px',
            'marginRight':'24px',
         },
         'marginTop': '15px'
      }}>
      <StyledButton size='small' onClick={()=>{
            navigate('/remote');
         }}>
            <StyledBox sx={location.pathname === '/remote' ? selectedStyle : null}>
               <RemoteIcon />
               REMOTE
            </StyledBox>
      </StyledButton >
      <StyledButton  onClick={()=>{
            navigate('/apps');
         }}>
            <StyledBox sx={location.pathname === '/apps' ? selectedStyle : null}>
               <AppsIcon/>
               APPS
            </StyledBox>
      </StyledButton>
      <StyledButton  onClick={()=>{
            navigate('/guide');
         }}>
            <StyledBox sx={location.pathname === '/guide' ? selectedStyle : null}>
               <ViewListIcon/>
               LIVE TV
            </StyledBox>
      </StyledButton>
      <StyledButton onClick={()=>{
            navigate('/devices');
         }}>
            <StyledBox  sx={location.pathname === '/devices' ? selectedStyle : null}>
               <TvIcon/>
               DEVICES
            </StyledBox>
      </StyledButton>
   </Box>
}

export default BottomNavBar;