import {useEffect, useState} from 'react';
import { useSelector, useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {setActiveDevice} from '../../app/reducers/index'
import styled from '@mui/system/styled'
import { useSendRemoteFunctionMutation, useLazyGetRoomControlsStateQuery} from "../../app/api";
//place holder content
// const DevicePage = (props)=>{
//    return <CenterAlignedBox sx={{flexGrow:1}}>
//       <Typography variant="h6">Devices page</Typography>
//    </CenterAlignedBox>
// }
//real page\

const StyledButton =  styled(Button)(({theme})=>({
   flexGrow: 1,
   flexBasis: '100%',
   margin: '8px',
   background: 'rgba(255, 255, 255, 0.1)'
}))

const DevicePage = (props)=>{
   const dispatch = useDispatch();
   const devices = useSelector((state)=>{
      return state.root.devices
   })
   const activeStb = useSelector((state)=>state.root.activeStb);
   const handleClick = (serialNumber, event)=>{
      console.log("Switching control to " + serialNumber);
      dispatch(setActiveDevice(serialNumber));
   }
   // const [tempExpanded, setExpanded] = useState(false)
   const [sendAction] = useSendRemoteFunctionMutation()
   const handleRoomControl = (action, event)=>{
      console.log("Sending room control action: " + action);
      console.log(devices)
      const payload = {
         'serialNumber': activeStb,
         'action':{
            'type': 'ROOM_CONTROL',
            'data': {
               'serialNumber': activeStb,
               roomNumber: devices[activeStb],
               ...room_controls[action]
            }
         }
      }
      sendAction(payload)
   }
   const [getRoomControlsState, {data, isLoading, isSuccess}] = useLazyGetRoomControlsStateQuery(activeStb);
   const [expandedControl, setExpandedControl] = useState(null);
   const handleRoomControlExpand = (panel)=>((event, expanded)=>{
      setExpandedControl(panel)   
   })

   useEffect(()=>{
      getRoomControlsState(activeStb)
   }, [getRoomControlsState, activeStb])

   useEffect(()=>{
      let requestInterval = null;
      if(expandedControl === 'thermostat'){
         requestInterval = setInterval(()=>{
            if(!isLoading){
               getRoomControlsState(activeStb).then((result)=>{
                  console.log(result)
               })
            }
         }, 15000)
      }
      else{
         if(requestInterval){
            clearInterval(requestInterval)
            requestInterval = null;
         }
      }
      return ()=>{
         if(requestInterval){
            clearInterval(requestInterval)
            requestInterval = null;
         }
         console.log("Cleaning up: tempExpanded")
      }
   }, [expandedControl, activeStb, getRoomControlsState])

   return <Grid direction='column' spacing={1} container id='devices-container' sx={{
      flexGrow:1,
      'paddingLeft':'48px', 
      'paddingRight':'48px',
      '@media (max-width: 358px)':{
         'paddingLeft':'24px',
         'paddingRight':'24px',
      },
   }}>
      <Typography variant="h6" sx={{textAlign:'center'}}>Televistion Control</Typography>
      {Object.keys(devices).map((serialNumber, index)=>{
         return <Grid key={'dev-'+index} item>
            <Paper variant="outlined" elevation={0} sx={{background: 'rgba(18, 18, 18, 0.4)'}}>
               <Box p={1} sx={{display:'flex', 'justifyContent': 'space-between'}}>
                  <Typography>{devices[serialNumber]}</Typography>
                  {activeStb === serialNumber ? 'Currently Active': <Button sx={{padding:'0px'}} size='small' onClick={handleClick.bind(this, serialNumber)}>Control</Button>}
               </Box>
            </Paper>
         </Grid>
      })}
      <Grid item>
         <Typography variant="h6" sx={{textAlign:'center'}}>In Room Controls</Typography>
         <MuiAccordion sx={{background: 'rgba(18, 18, 18, 0.4)'}} expanded={(expandedControl === 'lights')} onChange={handleRoomControlExpand('lights')}>
            <MuiAccordionSummary>Lights</MuiAccordionSummary>
            <MuiAccordionDetails sx={{display:'flex', flexWrap:"wrap"}}>
               <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'lightsMaster')}>Master</StyledButton>
               <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'lightsStairway')}>Stairway</StyledButton>
            </MuiAccordionDetails>
         </MuiAccordion>
         <MuiAccordion id='drape-controls'sx={{background: 'rgba(18, 18, 18, 0.4)'}}  expanded={(expandedControl === 'drapes')} onChange={handleRoomControlExpand('drapes')}>
            <MuiAccordionSummary>Sheer / Drapes</MuiAccordionSummary>
            <MuiAccordionDetails sx={{display:'flex', flexWrap:"wrap"}}>
               <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'sheerUp')}>Sheer Up</StyledButton>
               <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'sheerDown')}>Sheer Down</StyledButton>
               <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'drapeStop')}>Stop</StyledButton>
               <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'shadeUp')}>Shade Up</StyledButton>
               <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'shadeDown')}>Shade Down</StyledButton>
            </MuiAccordionDetails>
         </MuiAccordion>
         <MuiAccordion id='thermostat' sx={{background: 'rgba(18, 18, 18, 0.4)'}} expanded={(expandedControl === 'thermostat')} onChange={handleRoomControlExpand('thermostat')}>
            <MuiAccordionSummary>Thermostat</MuiAccordionSummary>
            <MuiAccordionDetails>
               <Box sx={{display:'flex'}}>
                  <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                     {data && data.thermostat ? (<>
                        <Box sx={{flexGrow:1, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography>Target {data.thermostat.targetTemp}</Typography>
                     </Box>
                     <Box sx={{flexGrow:1, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography>{data.thermostat.temp}</Typography>
                     </Box>
                     <Box sx={{flexGrow:1, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography>{acModes[data.thermostat.acMode]}</Typography>
                     </Box>
                     </>): null}
                  </Box>
                  <Box sx={{flexGrow:1, display:'flex', flexDirection:'column'}}>
                    <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'tempUnitChange')}>&deg;F|&deg;C</StyledButton>
                    <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'tempUp')}>UP</StyledButton>
                    <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'tempDown')}>DOWN</StyledButton>
                    <StyledButton variant="contained" onClick={handleRoomControl.bind(this, 'tempMode')}>Power</StyledButton>
                  </Box>
               </Box>
            </MuiAccordionDetails>
         </MuiAccordion>
      </Grid>
   </Grid>
}

const acModes = {
   0: 'Off',
   1: 'Fan Only',
   2: 'Fan Fixed',
   3: 'Auto',

}
//VERY HARDCODED INNCOM CONTROLS FOR THE CATBIRD DEMO SPECIFICALLY

const room_controls = {
   'sheerUp': {
      component: 'drapes',
      operation: 'up',
      operationExpansion: null,
      circuit: '2',
      value: null,
   },
   'sheerDown':{
      component: 'drapes',
      operation: 'down',
      operationExpansion: null,
      circuit: '2',
      value: null,
   },
   'drapeStop': {
      component: 'drapes',
      operation: 'stop',
      operationExpansion: null,
      circuit: '0',
      value: null,
   },
   'shadeUp': {
      component: 'drapes',
      operation: 'up',
      operationExpansion: null,
      circuit: '1',
      value: null,
   },
   'shadeDown': {
      component: 'drapes',
      operation: 'down',
      operationExpansion: null,
      circuit: '1',
      value: null,
   },
   'lightsMaster': {
      component: 'lights',
      operation: 'toggle',
      operationExpansion: null,
      circuit: '0',
      value: null,
   },
   'lightsEntry': {
      component: 'lights',
      operation: 'toggle',
      operationExpansion: null,
      circuit: '1',
      value: null,
   },
   'lightsKitchen': {
      component: 'lights',
      operation: 'toggle',
      operationExpansion: null,
      circuit: '2',
      value: null,
   },
   'lightsStairway': {
      component: 'lights',
      operation: 'toggle',
      operationExpansion: null,
      circuit: '3',
      value: null,
   },
   'lightsAccent': {
      component: 'lights',
      operation: 'toggle',
      operationExpansion: null,
      circuit: '4',
      value: null,
   },
   'tempUnitChange': {
      component: 'thermostat',
      operation: 'c/f',
      operationExpansion: null,
      circuit: null,
      value: 2,
   },
   'tempUp': {
      component: 'thermostat',
      operation: 'upTemp',
      operationExpansion: null,
      circuit: null,
      value: null,
   },
   'tempDown': {
      component: 'thermostat',
      operation: 'dnTemp',
      operationExpansion: null,
      circuit: null,
      value: null
   },
   'tempMode': {
      component: 'thermostat',
      operation: 'mode',
      operationExpansion: null,
      circuit: null,
      value: 3
   },
}

export default DevicePage;